<template>
  <div class="home">
    <h1>Hello world</h1>
  </div>
</template>

<script>
export default {
  name: 'home',
  components: {},
  data() {
    return {}
  },
  mounted() {},
  created() {},
  methods: {}
}
</script>
